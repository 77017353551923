
import { instance } from '@/utils/http.js'
/**
 * 面批模块相关接口
 **/

// 根据试卷id获取pdf文件
export const getCardPdf = function (data) {
  return instance({
    url: '/api/v1/papers/no_nstandard_pdf',
    method: 'post',
    data
  })
}

// 上传面批答题卡
export const uploadCard = function (data) {
  return instance({
    url: '/api/v1/papers/no_nstandard_upload',
    method: 'post',
    data
  })
}

// 面批答题卡列表
export const getUploadCard = function (params) {
  return instance({
    url: '/api/v1/papers/no_nstandard_list',
    method: 'get',
    params
  })
}



// 面批识别学生答题卡
export const onScanCard = function (data) {
  return instance({
    url: '/api/v1/papers/no_nstandard_identify',
    method: 'post',
    data
  })
}


//答题卡上传进度
export const uploadSheetSchedule = function (params) {
  return instance({
    url: '/api/v1/test/upload_sheet_schedule',
    method: 'get',
    params
  })
}


// 确认面批答题卡
export const confirmCard = function (data) {
  return instance({
    url: "/api/v1/papers/no_nstandard_confirm",
    method: 'post',
    data
  })
}

/**
 * 学生答题卡相关接口
 */

// 学生面批答题卡列表
export const studentCardList = function (params) {
  return instance({
    url: "/api/v1/papers/user_paper_list",
    method: 'get',
    params
  })
}

// 上传学生面批答题卡
export const uploadStudentCard = function (data) {
  return instance({
    url: '/api/v1/papers/user_paper_upload',
    method: 'post',
    data
  })
}


// 识别学生答题卡
export const identifyStudentCard = function (data) {
  return instance({
    url: '/api/v1/papers/user_paper_identify',
    method: 'post',
    data
  })
}

// 确认面批答题卡
export const confirmStudentCard = function (data) {
  return instance({
    url: "/api/v1/papers/user_paper_confirm",
    method: 'post',
    data
  })
}
