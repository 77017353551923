<template>
  <div class="scan_wrap">
    <div class="btn_wrap">
      <el-button type="primary"
                 @click="scanCard">识别</el-button>
    </div>
    <div class="main_wrap">
      <div class="left_img">
        <el-image :src="url"
                  :preview-src-list="srcList">

        </el-image>
      </div>
      <div class="right_form">
        <el-form ref="form"
                 :model="form"
                 label-width="80px">
          <el-form-item label="试卷id">
            <el-input v-model="form.edu_paper_id"></el-input>
          </el-form-item>
          <el-form-item label="准考证号">
            <el-input v-model="form.reg_nums"></el-input>
          </el-form-item>
          <el-form-item :label="index"
                        v-for="item,index in form.question_data"
                        :key="index">
            <el-input v-model="form.question_data[index]"></el-input>
          </el-form-item>

          <el-form-item :label="index"
                        v-for="item,index in form.scores"
                        :key="index">
            <el-input v-model="form.scores[index]"></el-input>
          </el-form-item>
        </el-form>
        <div class="bottom_btn_wrap">

          <el-button type="primary"
                     v-show="showSubmitBtn"
                     @click="submit"
                     plain>确定</el-button>
          <el-button @click="goback">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onScanCard, confirmCard } from '@/api/correctPaperCard.js'
export default {
  data () {
    return {
      form: {},
      srcList: [],
      url: '',
      paper_no_nstandard_sheet_id: null,
      showSubmitBtn: false
    }
  },
  mounted () {
    // this.url = require('@/assets/login/bg.png')
    // this.srcList.push(require('@/assets/login/bg.png'))
    this.$nextTick(() => {
      this.url = this.$route.query.url
      this.srcList.push(this.url)
      this.paper_no_nstandard_sheet_id = this.$route.query.paper_no_nstandard_sheet_id

    })

  },
  methods: {
    goback () {
      this.$router.go(-1)
    },
    async submit () {
      let form = {
        question_data: this.form.question_data,
        scores: this.form.scores,
        paper_no_nstandard_sheet_id: this.paper_no_nstandard_sheet_id,
        // paper_no_nstandard_sheet_id: 5,
        ticket_number: this.form.reg_nums
      }
      const { data } = await confirmCard(form)
      this.$message.success('操作成功')
      this.goback()
    },
    async scanCard () {
      const { data } = await onScanCard({
        paper_no_nstandard_sheet_id: this.paper_no_nstandard_sheet_id
        // paper_no_nstandard_sheet_id: 5

      })
      this.$message.success("识别成功")
      this.form = data
      this.showSubmitBtn = true
    }
  }
}
</script>

<style lang="scss"  scoped>
.scan_wrap {
  margin: 0 20px;
  .el-image {
    width: 100%;
  }
  ::v-deep .el-image__error {
    height: 400px;
  }
}
.main_wrap {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
  .right_form {
    min-width: 200px;
    max-width: 366px;
    margin: 10px 20px 0 30px;
    padding: 30px 40px 30px 30px;
    box-shadow: 1px 1px 10px #ddd;
    height: min-content;
    flex: 1;
    .el-form {
      padding-right: 30px;
      max-height: calc(100vh - 210px);
      overflow: auto;
    }
  }
  .left_img {
    flex: 1;
    min-width: 200px;
    max-width: 766px;
    height: min-content;
    margin: 10px 20px 0 0;
    box-shadow: 1px 1px 10px #ddd;
    border-radius: 5px;
  }
  .bottom_btn_wrap {
    display: flex;
    .el-button {
      flex: 1;
    }
  }
  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  /* 滚动条上的按钮 (上下箭头). */
  ::-webkit-scrollbar-button {
    display: none;
  }
  /* 滚动条上的滚动滑块. */
  ::-webkit-scrollbar-thumb {
    background-color: rgb(202, 202, 202);
    border-radius: 50px;
  }

  /* 滚动条没有滑块的轨道部分 */
  ::-webkit-scrollbar-track-piece {
    border-radius: 20px;
  }
  /* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}
</style>